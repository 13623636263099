import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/blog-layout"
import SEO from "../components/seo"
import GetStartedBanner from "../components/get-started-footer"

export default function Template(props) {
  const { data } = props

  const posts = data.allMarkdownRemark.edges
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
   const prevPage =
    currentPage - 1 === 1 ? "/blog" : `/blog/` + (currentPage - 1).toString() + `/`
  const nextPage = `/blog/` + (currentPage + 1).toString() + `/`

  const authorName = "Farouk Ben."

  return (
    <Layout>
      <SEO
        title={`Odown Product News and Updates`}
        description={`Improve your uptime monitoring game with the latest insights from Odown's Blog. Get practical tips, step-by-step tutorials, and expert advice to optimize your monitoring setup and avoid downtime.`}
        pathname={`/blog`}
        image={{
          src: "/general-image-og--odown-blog.png",
          width: 1000,
          height: 400,
        }}
      />
      <div className="blog-layout">
        <div className="container">
          <div className="blog-layout__content mr-0">
            <div className="layout-content__wrapper list">
              <h1>Odown product news and updates</h1>
              <p>
                Odown is the Api's simple monitoring platform for the modern
                stack: programmable, flexible and loving JavaScript.
              </p>
              <div className="articles-list">
                {posts.map(({ node }, index) => {
                  const blogTextContent = stripHtml(node.html)

                  return (
                    <div key={index} className="article-item">
                      <div className="article-item_inner">
                        <div className="article_featured-image">
                          {node.frontmatter.featuredImage !== null && (
                            <Link to={node.frontmatter.path}>
                              <Img
                                className="featued-image_content"
                                fadeIn={false}
                                loading="eager"
                                fluid={
                                  node.frontmatter.featuredImage.childImageSharp
                                    .fluid
                                }
                                alt={node.frontmatter.title}
                              />
                            </Link>
                          )}
                        </div>
                        <h2>
                          <Link to={node.frontmatter.path}>
                            {node.frontmatter.title}
                          </Link>
                        </h2>
                        <div className="article_excerpt">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: node.frontmatter.excerpt,
                            }}
                          />
                        </div>
                        <div className="btn-wrapper">
                          <div className="post-info">
                            <div className="post-author">
                              By{" "}
                              <a
                                href="https://twitter.com/ben__farouk"
                                target="_blanc"
                              >
                                {authorName}
                              </a>
                            </div>
                            <div className="post-readingtime">
                              {blogTextContent !== null
                                ? `${readingTime(blogTextContent)} minute read`
                                : ``}
                            </div>
                          </div>
                          <Link
                            className="btn-primary"
                            to={node.frontmatter.path.endsWith('/') ? node.frontmatter.path : `${node.frontmatter.path}/`}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {numPages > 1 && (
                <div className="articles-pagination">
                  <ul>
                    {!isFirst && (
                      <li>
                        <Link to={prevPage} rel="prev">
                          ← Previous Page
                        </Link>
                      </li>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                      <li
                        className={currentPage === i + 1 ? "active" : ""}
                        key={`pagination-number${i + 1}`}
                      >
                        <Link to={`/blog${i === 0 ? "/" : `/${i + 1}/`}`}>
                        {i + 1}
                        </Link>
                      </li>
                    ))}
                    {!isLast && (
                      <li>
                        <Link to={nextPage} rel="next">
                          Next Page →
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <GetStartedBanner />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { path: { glob: "/blog/*" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            path
            excerpt
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`

/**
 *
 * @param {*} html
 * @returns
 */
function stripHtml(html) {
  if (typeof document !== "undefined" && document) {
    // Create a new div element
    var temporalDivElement = document.createElement("div")
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || ""
  }
  return null
}
/**
 *
 * @param {*} text
 * @returns
 */
function readingTime(text) {
  const wpm = 225
  const words = text.trim().split(/\s+/).length
  const time = Math.ceil(words / wpm)
  return time
}
